// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import '../stylesheets/bootstrap_custom.scss'
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js"
import Cookies from "../plugins/cookies.js";
import MainNav from "../plugins/main_nav.js";
import BackToTop from "../plugins/back_to_top";
import Share from "../plugins/share.js";
import Autocompleter from '../plugins/autocompleter.js'
import SearchForm from '../plugins/search_form.js'

require("jquery")

Rails.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', function(){
  Cookies.init();
  MainNav.init();
  BackToTop.init();
  Share.init(bootstrap);
  Autocompleter.initChosen();
  SearchForm.init();
}, false);
