// require("jquery")
import "./back_to_top.scss";

const BackToTop = {
  init(){
    if (document.getElementsByClassName('back-to-top').length == 0) {
      this.createElement()
    }
    $(window).scroll(function() {
      if ($(window).scrollTop() > 350) {
        $('a.back-to-top').fadeIn('slow');
      } else {
        $('a.back-to-top').fadeOut('slow');
      }
    });  
  },
  createElement(){
    // Rails: 
    // <%= link_to t(:back_to_top), '#top', data: {scroll: true}, data: {turbolinks: false}, class: 'back-to-top', title: I18n.t(:back_to_top) %>
    // HTML:
    // <a href='#top' data-turbolinks='false' data-scroll='true' title='Volver arriba' class='back-to-top'>Volver arriba</a>

    let link = document.createElement("a");
    link.setAttribute('href', '#top');
    link.setAttribute('class', 'back-to-top');
    //a.textContent = 'Volver arriba';
    link.setAttribute('title', 'Volver arriba');
    link.setAttribute('data-turbolinks', 'false');
    link.setAttribute('data-scroll', true);
    var element = document.getElementById("app");
    element.appendChild(link);
  }
}

export default BackToTop
