require("chosen-js")
require("chosen-js/chosen.css")
require("../stylesheets/bootstrap4_chosen.min.css")

const Autocompleter = {
  initChosen() {
    $('.chosen-select').chosen({
      no_results_text: "Sin resultados para",
      placeholder_text_single: " ",
      placeholder_text_multiple: " "
    })
  },
}

export default Autocompleter