const SearchForm = {
  init(){
    $('a#reset_search').on('click', function(){
      var form = $(this).parents('form[id*=search]');
      $(form).find('input[type!=submit]').each(function(index){
        $(this).val('')
      })
      $(form).find('select').each(function(index){
        $(this).val('').trigger('chosen:updated');
      })
      return false;
    })
  },
  // // Old search_form was used for header search dropdown
  // init() {
  //   var input = $("#search-input");
  //   var label = $("#search-label");
    
  //   // register clicks and toggle classes
  //   $(label).on("click",function(){
  //     if ($(input).hasClass('show')) {
  //       $(input).removeClass('show');
  //       $(label).removeClass('show');
  //     } else {
  //       $(input).addClass('show');
  //       $(input).find('input[type=search]').focus();
  //       $(label).addClass('show');
  //     }
  //     return false;
  //   });

  //   // register clicks outisde search box, and toggle correct classes
  //   $(document).on("click",function(e){
  //     var clickedID = e.target.id;
  //     if (clickedID != "search-input" && clickedID != "search-label" && clickedID != "search-input-text") {
  //       if ($(input).hasClass("show")) {
  //         $(input).removeClass('show');
  //         $(label).removeClass('show');
  //       }
  //     }
  //   });
  // },
}

export default SearchForm
